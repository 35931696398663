/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEqual, isNil, keys, pick } from 'lodash';
import { LOCATION_US } from '../constants/entities/location';
import { Appraisal, ConditionAppraisalPhoto } from '../graphql-types';
import ComplexIdGenerator from './ComplexIdGenerator';
import { validate } from 'uuid';

export function checkNumberInRange(firstNumber: number, secondNumber: number) {
  const lowerBound = firstNumber - firstNumber * 0.3;
  const upperBound = firstNumber + firstNumber * 0.3;

  if (secondNumber >= lowerBound && secondNumber <= upperBound) {
    return true;
  }

  return false;
}

export const getZIPCodeWithLocationUS = (location: string) => {
  const filterLocation = LOCATION_US.filter(
    (it) => it.segmentation === location
  );

  if (!isNil(filterLocation[0]?.postalCode)) {
    return filterLocation[0]?.postalCode;
  }

  return 'null';
};

export const isDirty = (origin: unknown, second: unknown) => {
  const mutchKeys = keys(origin);

  const a = pick(origin, mutchKeys);
  const b = pick(second, mutchKeys);

  return !isEqual(a, b);
};

export const formatNumber = (num: number, variant: 'price' | 'distance') => {
  switch (variant) {
    case 'price':
      return new Intl.NumberFormat('ru-RU').format(num); // decimal separator -> space 22 000
    case 'distance':
      return new Intl.NumberFormat().format(num);
  }
};

export const formatDate = (date: string, variant: 'full' | 'small' = 'full') =>
  variant == 'full'
    ? new Date(date).toLocaleString()
    : new Date(date).toLocaleDateString();

export const convertHexToRGB = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : '';
};

export function isMobile() {
  if (window) {
    return window.matchMedia(`(max-width: 767px)`).matches;
  }
  return false;
}

export function isMdScreen() {
  if (window) {
    return window.matchMedia(`(max-width: 1199px)`).matches;
  }
  return false;
}

function currentYPosition(elm: any) {
  if (!window && !elm) {
    return;
  }
  if (elm) return elm.scrollTop;
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) return window.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(elm: any) {
  let y = elm.offsetTop;
  let node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}

export function scrollTo(scrollableElement: any, elmID: any) {
  const elm = document.getElementById(elmID);

  if (!elmID || !elm) {
    return;
  }

  const startY = currentYPosition(scrollableElement);
  const stopY = elmYPosition(elm);

  const distance = stopY > startY ? stopY - startY : startY - stopY;
  if (distance < 100) {
    scrollTo(0, stopY);
    return;
  }
  let speed = Math.round(distance / 50);
  if (speed >= 20) speed = 20;
  const step = Math.round(distance / 25);
  let leapY = stopY > startY ? startY + step : startY - step;
  let timer = 0;
  if (stopY > startY) {
    for (let i = startY; i < stopY; i += step) {
      setTimeout(
        (function (leapY) {
          return () => {
            scrollableElement.scrollTo(0, leapY);
          };
        })(leapY),
        timer * speed
      );
      leapY += step;
      if (leapY > stopY) leapY = stopY;
      timer++;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    setTimeout(
      (function (leapY) {
        return () => {
          scrollableElement.scrollTo(0, leapY);
        };
      })(leapY),
      timer * speed
    );
    leapY -= step;
    if (leapY < stopY) leapY = stopY;
    timer++;
  }
  return false;
}

function base64ToBlob(base64: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function createLocalUrlFromBase64(base64Image: string) {
  const contentType = base64Image.match(/data:(.*);base64/)?.[1] || 'image/png';
  const base64Data = base64Image.replace(
    /^data:image\/(png|jpeg|jpg);base64,/,
    ''
  );

  const blob = base64ToBlob(base64Data, contentType);
  return URL.createObjectURL(blob);
}

export const imageSrcBySize = (
  appraisalId: number,
  src: string,
  x?: 170 | 370 | 644,
  y?: 109 | 278 | 414
) => {
  if (!src) return '';
  if (src.includes('data:image')) return createLocalUrlFromBase64(src);
  const webPath = src.replace(/(http)?s?:?\/\//, '');

  if (x && y)
    return `https://www.hgregoire.com/photos-service/by-size/evaluation/${appraisalId}/${x}x${y}/${webPath}`;

  return src;
};

export const buildCarTitle = (
  modelYear?: number | string | null,
  make?: string | null,
  model?: string | null,
  serie?: string | null,
  traction?: string | null,
  transmissionType?: string | null,
  buyerCode?: string | null
) => {
  const value = [
    modelYear,
    make,
    model,
    serie,
    traction,
    transmissionType,
    buyerCode ? `[${buyerCode}]` : '',
  ].filter((it) => it);

  return value.join(' ').trim();
};

export const validateVin = (vin: string) => {
  const pattern =
    /^([A-HJ-NPR-Z\d]{3})([A-HJ-NPR-Z\d]{5})([\dX])(([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d])([A-HJ-NPR-Z\d]{6}))$/;

  return !!vin.toUpperCase().match(pattern);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAllFilled = (obj: any, keys: string[]): boolean =>
  keys.every((it) => !!obj[it] === true);

export const readFile = (file: File) =>
  new Promise<string>((resolve, reject) => {
    if (!file) reject('File not selected');

    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      if (!event.target?.result) return reject('File is empty');

      return resolve(event.target.result.toString());
    });

    reader.addEventListener('error', (event) => {
      reject(`File is read with error: ]${event.target?.error}`);
    });

    reader.readAsDataURL(file);
  });

// It filter backend data in second time Why I dont'know
export const appraisalFilter = (
  search: string,
  item: {
    id: string | number;
    vin: string;
    location: string;
    modelYear: number;
    owner: string;
    make: string;
    model: string;
    series: string;
    custFirstName: string;
    custLastName: string;
    custHomePhone: string;
  }
) => {
  const compare = (
    item.vin +
    item.id +
    item.location +
    item.modelYear +
    item.owner +
    item.make +
    item.model +
    item.series +
    item.custFirstName +
    item.custLastName +
    item.custHomePhone +
    item.id
  )
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase();

  const terms = search
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .split(' ');

  for (const term of terms) {
    if (!new RegExp(term.toLowerCase()).test(compare)) {
      return false;
    }
  }
  return true;
};

export const listingFilter = (
  search: string,
  item: {
    id: string | number;
    vin: string;
    location: string;
    modelYear: string;
    owner: string;
    referenceTitle: string;
    referenceId: string;
    make: string;
    model: string;
    series: string;
    custName: string;
    custSmsNumber: string;
  }
) => {
  const compare = (
    item.vin +
    item.id +
    item.location +
    Number(item.modelYear) +
    item.owner +
    item.referenceTitle +
    item.referenceId +
    item.make +
    item.model +
    item.series +
    item.custName +
    item.custSmsNumber +
    item.id
  )
    .replace(/[^a-zA-Z0-9]/g, '')
    .toLowerCase();

  const terms = search
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLowerCase()
    .split(' ');

  for (const term of terms) {
    if (!new RegExp(term.toLowerCase()).test(compare)) {
      return false;
    }
  }
  return true;
};

export const findDublicateNumber = (arr1: string[], arr2: string[]) => {
  const isPresent = arr1.filter(function (val) {
    return arr2.indexOf(val) !== -1;
  });

  return !!isPresent.length;
};

const isDefined = (value: any) => value !== undefined && value !== null;

export const typedAppraisal = (appraisal: any) => {
  return {
    ...appraisal,

    ...(Object.hasOwn(appraisal, 'appraisalValue')
      ? {
          appraisalValue: isDefined(appraisal.appraisalValue)
            ? Number(appraisal.appraisalValue)
            : appraisal.appraisalValue,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'odometer')
      ? {
          odometer: isDefined(appraisal.odometer)
            ? Number(appraisal.odometer)
            : appraisal.odometer,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'ownerId')
      ? {
          ownerId: isDefined(appraisal.ownerId)
            ? Number(appraisal.ownerId)
            : appraisal.ownerId,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'assessedById')
      ? {
          assessedById: isDefined(appraisal.assessedById)
            ? Number(appraisal.assessedById)
            : appraisal.assessedById,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'exitStrategyType')
      ? {
          exitStrategyType: isDefined(appraisal.exitStrategyType)
            ? Number(appraisal.exitStrategyType)
            : appraisal.exitStrategyType,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'eraInvoiceAmount')
      ? {
          eraInvoiceAmount: isDefined(appraisal.eraInvoiceAmount)
            ? Number(appraisal.eraInvoiceAmount)
            : appraisal.eraInvoiceAmount,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'completedById')
      ? {
          completedById: isDefined(appraisal.completedById)
            ? Number(appraisal.completedById)
            : appraisal.completedById,
        }
      : {}),

    ...(Object.hasOwn(appraisal, 'modelYear')
      ? {
          modelYear: isDefined(appraisal.modelYear)
            ? String(appraisal.modelYear)
            : appraisal.modelYear,
        }
      : {}),
  };
};

export function getRemovedConditionIds(
  originalAppraisal: Appraisal,
  modifiedAppraisal: Appraisal
): string[] {
  // Extract condition IDs from original and modified appraisals
  const originalConditionIds = originalAppraisal.conditions.map(
    ({ label, pin }) =>
      ComplexIdGenerator.create({
        label,
        pinX: pin.x,
        pinY: pin.y,
      })
  );

  const modifiedConditionIds = modifiedAppraisal.conditions.map(
    ({ label, pin }) =>
      ComplexIdGenerator.create({
        label,
        pinX: pin.x,
        pinY: pin.y,
      })
  );

  // Find condition IDs that are in the original but not in the modified
  const removedConditionIds = originalConditionIds.filter(
    (id) => !modifiedConditionIds.includes(id)
  );

  return removedConditionIds;
}

export function getRemovedPhotoServerIds(
  originalPhotos?: ConditionAppraisalPhoto[],
  updatedPhotos?: ConditionAppraisalPhoto[]
): string[] {
  const updatedSrcs = new Set(
    (updatedPhotos ?? [])
      .filter((photo) => validate(photo.src))
      .map((photo) => photo.src)
  );

  return (originalPhotos ?? [])
    .filter((photo) => validate(photo.src) && !updatedSrcs.has(photo.src))
    .map((photo) => photo.src);
}
